<!-- eslint-disable vue/attribute-hyphenation for _groupHover -->
<template>
  <mp-flex :margin-right="!withChild && ['0px', '60px']">
    <!-- max-height="calc(100vh - 60px)" -->
    <mp-box
      :max-height="`calc(100vh - ${bannerHeightTotal + 60}px)`"
      border-right="1px"
      border-color="gray.100"
      transition="all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0s"
      :background-color="withChild ? 'ash.100' : 'background'"
      :position="isStacked ? 'relative' : 'absolute'"
      :width="isToggle ? '3.75rem' : '13.5rem'"
      :max-width="isToggle ? '3.75rem' : '13.5rem'"
      :display="['block']"
      margin-right="-60px"
      z-index="1"
      :box-shadow="sidebarBoxShadow"
    >
      <mp-box
        as="section"
        class="sidebar-content"
        data-id="sidebar"
        width="inherit"
        :height="`calc(100vh - ${bannerHeightTotal + 60}px)`"
        overflow-y="auto"
        overflow-x="hidden"
        padding-top="4"
        padding-x="2"
        padding-bottom="60px"
        @mouseenter="handleMouseEnter()"
        @mouseleave="handleMouseLeave()"
      >
        <mp-box v-for="(menu, index) in menus" :key="menu.id">
          <mp-flex flex-direction="column">
            <mp-pseudo-box
              role="group"
              flex="1"
              border-radius="md"
              as="router-link"
              transition="all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0s"
              padding-x="2.5"
              padding-y="2"
              cursor="pointer"
              :to="menu.link"
              :background-color="menu.isActive ? 'blue.100' : 'inherit'"
              :color="menu.isActive ? 'blue.400' : 'inherit'"
            >
              <mp-flex
                position="relative"
                direction="row"
                align="center"
                justify="space-betwem"
              >
                <mp-box
                  v-if="menu.isShowBagde && isToggle"
                  position="absolute"
                  left="16px"
                  top="0"
                  width="3"
                  height="3"
                  border-radius="full"
                  background="rose.400"
                  border="2px solid"
                  :border-color="withChild ? 'ash.100' : 'background'"
                />
                <mp-stack
                  width="full"
                  direction="row"
                  align="center"
                  justify="space-betwem"
                >
                  <mp-icon
                    :name="menu.icon"
                    :variant="menu.isActive ? 'fill' : 'outline'"
                    :color="menu.isActive ? 'blue.400' : 'gray.600'"
                    :_groupHover="{
                      color: 'blue.400',
                      cursor: 'pointer',
                    }"
                  />
                  <mp-text
                    white-space="nowrap"
                    :font-weight="menu.isActive ? 'semibold' : 'inherit'"
                    :color="menu.isActive ? 'blue.400' : 'dark'"
                    :opacity="isToggle ? '0' : '1'"
                    :_groupHover="{
                      color: 'blue.400',
                      cursor: 'pointer',
                    }"
                  >
                    {{ menu.name }}
                  </mp-text>
                </mp-stack>
                <mp-badge
                  v-if="menu.isShowBagde && !isToggle"
                  variant="solid"
                  variant-color="red"
                  >{{ menu.badgeText }}</mp-badge
                >
              </mp-flex>
            </mp-pseudo-box>
          </mp-flex>
          <mp-divider v-if="sidebarDivider(menus, menu, index)" />
        </mp-box>
        <mp-flex
          gap="2"
          :background-color="withChild ? 'ash.100' : 'background'"
          flex-direction="row"
          position="fixed"
          width="inherit"
          bottom="0"
          left="0"
          align-items="center"
          border-top="1px solid"
          border-right="1px solid"
          border-top-color="gray.100"
          border-right-color="gray.100"
          padding="2"
          h="68px"
        >
          <mp-tooltip
            position="right"
            :show-delay="200"
            :label="
              !isForced
                ? 'Click to expand or shortcut [shift] + [x]'
                : 'Click to collapse or shortcut [shift] + [x]'
            "
            use-portal
          >
            <mp-pseudo-box
              role="group"
              border-radius="sm"
              transition="all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0s"
              padding-x="2.5"
              padding-y="2"
              :_hover="{
                backgroundColor: 'blue.100',
                color: 'blue.400',
                cursor: 'pointer',
              }"
              @click="handleToggle"
            >
              <mp-stack direction="row" align="center">
                <mp-icon
                  :name="!isForced ? 'arrow-expand' : 'arrow-collapse'"
                  size="sm"
                />
              </mp-stack>
            </mp-pseudo-box>
          </mp-tooltip>
          <mp-text
            font-size="sm"
            color="dark"
            white-space="nowrap"
            :opacity="isToggle ? '0' : '1'"
            is-truncated
          >
            Company ID : {{ companyId }}
          </mp-text>
        </mp-flex>
      </mp-box>
    </mp-box>
    <MekariSidebarChild
      v-if="withChild"
      :isStacked="isStacked"
      :menus="menus"
      :submenus="submenus"
      @toggle="handleChildToggle"
    />
  </mp-flex>
</template>

<script>
import { mapState } from 'vuex'
import MekariSidebarChild from './MekariSidebarChild.vue'

export default {
  name: 'LayoutsMainSidebar',
  components: {
    MekariSidebarChild,
  },
  props: {
    bannerHeightTotal: {
      type: Number,
      default: 60,
    },
    isCollapsed: [Boolean],
    defaultIsToggle: [Boolean],
    isAlternate: [Boolean],
    isCustom: [Boolean],
    withChild: [Boolean],
    hasSubMenu: [Boolean],
    menus: {
      type: [Array, Object],
      default: () => [],
    },
    submenus: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      isStacked: !this.defaultIsToggle || false,
      isForced: this.isAlternate || false,
      isToggle: this.isCollapsed
        ? this.defaultIsToggle || true
        : this.defaultIsToggle || false,
      isChildToggle: true,
    }
  },
  computed: {
    ...mapState('template', ['credentials']),
    companyId() {
      if (this.credentials?.length && this.credentials[0]?.company_id)
        return this.credentials[0]?.company_id
      else return null
    },
    sidebarBoxShadow() {
      if (this.withChild) {
        if (this.isStacked) {
          return 'none'
        } else {
          return 'lg'
        }
      } else if (!this.withChild) {
        if (this.isStacked) {
          return 'none'
        } else {
          return 'lg'
        }
      } else {
        return 'none'
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  methods: {
    sidebarChildCondition() {
      return (
        this.$auth.user &&
        this.submenus.length > 0 &&
        !this.$route.name.includes('finances-package_usage')
      )
    },
    handleMouseEnter() {
      if (!this.isForced) {
        !this.isToggle && this.isCollapsed
          ? (this.isToggle = true)
          : (this.isToggle = false)
      }
    },
    handleMouseLeave() {
      if (!this.isForced) {
        !this.isToggle && this.isCollapsed
          ? (this.isToggle = false)
          : (this.isToggle = true)
      }
    },
    handleToggle() {
      this.isToggle = !this.isToggle
      this.isForced = !this.isForced
      if (this.isCustom) {
        this.isToggle = false
        this.isStacked = !this.isStacked
      }

      this.handleMouseLeave()
    },
    handleChildToggle(e) {
      this.isChildToggle = e
    },
    handleKeydown(e) {
      const SHIFTKEY = e.shiftKey
      const KEYCODE_X = e.keyCode === 88
      if (SHIFTKEY && KEYCODE_X) {
        this.handleToggle()
        this.handleMouseLeave()
      }
    },
    sidebarDivider(menus, menu, index) {
      if (
        index < menus.length - 1 &&
        menus[index + 1].category !== menu.category
      ) {
        return true
      } else if (index === menus.length - 1) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style scoped lang="scss">
/* custom scroll bar */
.sidebar-content::-webkit-scrollbar {
  width: 0px;
}
.sidebar-content::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}
.sidebar-content:hover::-webkit-scrollbar {
  width: 0px;
  position: absolute;
}
.sidebar-content:hover::-webkit-scrollbar-thumb {
  background: #8b95a5;
}
a:hover {
  text-decoration: none;
}
</style>
